import type { FC } from 'react'
import { useMemo } from 'react'
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Text,
  VStack
} from '@chakra-ui/react'
import { MdOutlineFilterList } from 'react-icons/md'
import groupBy from 'lodash/groupBy'
import type { RelatedProductItem } from 'ecosystem'
import { deliveryFactory } from 'api'
import { overrideText } from 'ui/lib/overrides'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import { toSentenceCase } from 'shared-utils'
import { useProductContext } from '../hooks/useProductContext'
import RelatedCartItem from './RelatedCartItem'

interface ProductSubProductsOverrides {
  accessories: string
}

interface ProductSubProductsProps {
  subProducts: RelatedProductItem[]
}

/** generates an accordion group containing related p with "CUSTOM" type */
const ProductSubProducts: FC<ProductSubProductsProps> = ({ subProducts }) => {
  const overrides = useOverridesContext<keyof ProductSubProductsOverrides>()
  const { onAddToCart, deliveryOptions } = useProductContext()

  const subProductGroups = useMemo<Record<string, RelatedProductItem[]>>(
    () => groupBy(subProducts, 'related.customRelationType'),
    [subProducts]
  )

  const renderCustomRelationTypeName = (customRelationType: string) => {
    const customRelationTypeDict = {
      ACCESSORY: overrideText('Tillbehör', overrides?.accessories)
    }

    return (
      customRelationTypeDict[customRelationType as keyof typeof customRelationTypeDict] ??
      toSentenceCase(customRelationType)
    )
  }

  if (!subProducts.length) {
    return null
  }

  return (
    <Accordion allowToggle className="ProductSubProducts" w="100%">
      {Object.keys(subProductGroups).map((customRelationType) => (
        <AccordionItem borderTop="none" key={customRelationType}>
          <AccordionButton
            _focus={{ outline: 'none' }}
            aria-label="related"
            px={0}
            gap={2}
            fontSize="sm">
            <MdOutlineFilterList />
            <Text>{renderCustomRelationTypeName(customRelationType)}</Text>
          </AccordionButton>
          <AccordionPanel borderColor="gray.200" borderTop="1px solid">
            {subProductGroups[customRelationType].map((r) => {
              const delivery = deliveryFactory(deliveryOptions, r.product.deliveryId)
              return (
                <VStack fontSize="xs" key={r.product.id} py={2} spacing={1} w="100%">
                  <RelatedCartItem
                    cartItem={r.product}
                    isEditable
                    isNew
                    isNotAvailable={!delivery?.buyable}
                    isNotAvailableText={delivery?.label}
                    key={r.related.relatedProductId}
                    onAddToCart={({ product: p, qty }) => {
                      onAddToCart({
                        id: p.id,
                        type: 'SINGLE',
                        qty,
                        meta: {
                          singleType: {
                            product: p
                          }
                        }
                      })
                    }}
                  />
                </VStack>
              )
            })}
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  )
}

export default ProductSubProducts
