'use client'
import { useCallback, useMemo } from 'react'
import { useDispatch } from 'shared-redux'
import { usePathname, useRouter } from 'next/navigation'
import {
  type ProductsState,
  resetFilters,
  setSelectedAvailableFilters,
  setSelectedProductFilter,
  useLazyGetListProductsQuery
} from 'shared-redux/state'
import type { FilterDTO, SortingOption } from 'ecosystem'
import { FilterType } from 'ecosystem'
import type { GetProductEndpointPayload } from 'api'
import { searchQueryParamsFactory } from 'api'
import lodashCloneDeep from 'lodash/cloneDeep'
import { paramsFromObj } from 'shared-utils'
import { usePathnameChanged } from 'ui/hooks'

const useUpdateSearch = (
  productsState: ProductsState,
  defaultPriceRange: [number, number],
  sortingOptions?: SortingOption[]
) => {
  const router = useRouter()
  const dispatch = useDispatch()
  const {
    filter: currentFilter,
    size: currentSize,
    selectedFilterCheckboxes: selectedCheckboxes,
    selectedFilterNumericValues: selectedNumericValues,
    availableFilters,
    priceRangeFilter: priceRange,
    sortOrder,
    sortBy,
    selectedFilterBrandIds: selectedBrandIds
  } = productsState

  const selectedSorting = { sortBy, sortOrder }

  const [fetchProducts, { isFetching: isLoading }] = useLazyGetListProductsQuery()
  const pathname = usePathname()
  const isBrandsView = !!pathname?.includes('varumarken/')

  const selectedFilters: FilterDTO[] = useMemo(() => {
    const textFilters = selectedCheckboxes.map((selectedCheckbox) => {
      const matchOption = availableFilters.find((f) => f.name === selectedCheckbox.id)
      return {
        name: matchOption?.name || '',
        label: matchOption?.label || '',
        type: FilterType.TEXT,
        textValues: selectedCheckbox.values,
        numberValues: [],
        visible: true
      }
    })

    const numericFilters = selectedNumericValues.map((selectedNumericValue) => {
      const matchOption = availableFilters.find((f) => f.name === selectedNumericValue.id)
      return {
        name: matchOption?.name || '',
        label: matchOption?.label || '',
        type: FilterType.NUMBER,
        textValues: [],
        numberValues: selectedNumericValue.range,
        visible: true
      }
    })

    return [...textFilters, ...numericFilters]
  }, [availableFilters, selectedCheckboxes, selectedNumericValues])

  const updateSearch = useCallback(() => {
    const updatedFilter = {
      ...(currentFilter ? currentFilter.filter : {}),
      ...{
        minPrice: priceRange[0],
        maxPrice: priceRange[1],
        filters: selectedFilters
      }
    }

    if (!isBrandsView) {
      if (selectedBrandIds.length) {
        updatedFilter.brandIds = selectedBrandIds
      } else {
        delete updatedFilter.brandIds
      }
    }

    const params: GetProductEndpointPayload = {
      filter: updatedFilter,
      config: {
        size: currentSize,
        queryParams: {
          ...(Boolean(selectedSorting.sortBy) && { 'sort.by': selectedSorting.sortBy }),
          ...(Boolean(selectedSorting.sortOrder) && { 'sort.order': selectedSorting.sortOrder })
        }
      }
    }

    const paramsForQuery = lodashCloneDeep(params)

    if (isBrandsView) {
      delete paramsForQuery.filter.brandIds
    }

    const queryParams = searchQueryParamsFactory(paramsForQuery)

    router.push(`${pathname}?${paramsFromObj(queryParams)}`)
    void fetchProducts(params)

    // updates the state
    dispatch(setSelectedProductFilter(params))
  }, [
    currentFilter,
    priceRange,
    selectedFilters,
    isBrandsView,
    currentSize,
    selectedSorting.sortBy,
    selectedSorting.sortOrder,
    router,
    pathname,
    fetchProducts,
    dispatch,
    selectedBrandIds
  ])

  const clearFilters = useCallback(() => {
    const updatedFilter = {
      ...(currentFilter ? currentFilter.filter : {})
    }

    // resets filters
    updatedFilter.filters = []
    updatedFilter.minPrice = defaultPriceRange[0]
    updatedFilter.maxPrice = defaultPriceRange[1]
    if (!isBrandsView) {
      updatedFilter.brandIds = []
    }

    const defaultSortingOption = sortingOptions?.find((o) => o.default)

    const params = {
      filter: updatedFilter,
      config: {
        size: currentSize,
        ...(defaultSortingOption
          ? {
              queryParams: {
                'sort.by': defaultSortingOption.sortBy,
                'sort.order': defaultSortingOption.sortOrder
              }
            }
          : {})
      }
    } as GetProductEndpointPayload

    router.push(pathname ?? '')

    void fetchProducts(params)
    // updates the state
    dispatch(setSelectedProductFilter(params))
    dispatch(resetFilters(null))
  }, [
    currentFilter,
    defaultPriceRange,
    isBrandsView,
    sortingOptions,
    currentSize,
    router,
    pathname,
    fetchProducts,
    dispatch
  ])

  usePathnameChanged(({ meta }) => {
    if (meta.isPathChanged) {
      dispatch(setSelectedAvailableFilters({ options: [], brands: [] }))
      dispatch(resetFilters(null))
    }
  })

  return {
    updateSearch,
    clearFilters,
    isLoading
  }
}

export default useUpdateSearch
