'use client'
import type { FC } from 'react'
import type { NumberInputProps } from '@chakra-ui/react'
import { NumberSelector } from 'ui'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import { overrideText } from 'ui/lib/overrides'
import { type QuantityOverrides } from '../../types'

type IProductDetailAddToCartCounterProps = NumberInputProps

const ProductDetailAddToCartCounter: FC<IProductDetailAddToCartCounterProps> = ({ ...props }) => {
  const overrides = useOverridesContext<keyof QuantityOverrides>()

  return (
    <NumberSelector
      formControlProps={{ w: 'auto' }}
      size="lg"
      hiddenLabel={overrideText('Kvantitet', overrides?.quantity)}
      id="product-qty"
      min={1}
      {...props}
    />
  )
}

export default ProductDetailAddToCartCounter
