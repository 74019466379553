'use client'
import type { FC } from 'react'
import { HStack, Stack } from '@chakra-ui/react'
import { GrandText, ImageBox } from 'ui'
import { useRouter } from 'next/navigation'
import type { BundleFullDTO } from 'ecosystem'
import { getProductImages } from 'api'
import { useProductContext } from '../hooks/useProductContext'

interface IBundleItemProps {
  bundleItem: BundleFullDTO
}

/** V-stackable product item for cart and sub-product views */
const BundleItem: FC<IBundleItemProps> = ({ bundleItem }) => {
  const router = useRouter()
  const { mainImageUrl } = getProductImages(bundleItem.product.medias)
  const { paths } = useProductContext()

  return (
    <Stack
      _hover={{
        transform: 'translate(-0.5rem)'
      }}
      cursor="pointer"
      direction={{ base: 'column', sm: 'row' }}
      onClick={() => router.push(`${paths.productsUrlPath}/${bundleItem.product.slug}`)}
      transition="all 0.07s cubic-bezier(.17,.67,.83,.67)"
      w="100%">
      <HStack w="100%">
        <ImageBox
          h={8}
          imageProps={{
            title: bundleItem.product.name,
            alt: bundleItem.product.name,
            src: mainImageUrl,
            style: {
              objectFit: 'contain'
            },
            sizes: '5vw'
          }}
          p={0}
          w={8}
        />
        <GrandText display={{ base: 'none', sm: 'initial' }} flexGrow={1} isTruncated>
          {bundleItem.product.name}
        </GrandText>
        <GrandText color="text.mild" w="auto">
          {bundleItem.quantity ?? '0'}x
        </GrandText>
      </HStack>
    </Stack>
  )
}

export default BundleItem
