import { useDispatch } from 'shared-redux'
import { useCallback, useEffect, useMemo } from 'react'
import { useSearchParams } from 'next/navigation'
import type { OldStoreProductFilterDTO, ProductLightDTO, SortingOption } from 'ecosystem'
import {
  type ProductsState,
  setSelectedAvailableFilters,
  setSelectedProductFilter,
  setSelectedProductListId,
  setSelectedProducts,
  setSelectedSize,
  useLazyGetListProductsQuery
} from 'shared-redux/state'
import type { GetProductEndpointPayload } from 'api'
import { PAGE_SIZE } from 'shared-redux/state/slices/products/constants'
import useInitialProductsRequest from './useInitialProductsRequest'

interface HookReturnType {
  products: ProductLightDTO[]
  totalElements: number
  isNextPageAvailable: boolean
  loadMore: () => void
  isLoading: boolean
}

interface UseProductListProps {
  id: string
  type: 'category' | 'brand' | 'search' | 'tag'
  filterConfig: OldStoreProductFilterDTO
  productsState: ProductsState
  getInitialProducts: string
  sortingOptions?: SortingOption[]
}

const useProductList = ({
  id,
  type,
  productsState,
  filterConfig,
  getInitialProducts,
  sortingOptions
}: UseProductListProps): HookReturnType => {
  const dispatch = useDispatch()
  const query = useSearchParams()

  const updatedSearchParams = useMemo(() => {
    const searchParams = new URLSearchParams(query?.toString())

    if (!searchParams.has('sortBy') && !searchParams.has('sortOrder')) {
      const defaultSearchOption = sortingOptions?.find((o) => o.default)

      if (defaultSearchOption) {
        searchParams.append('sortBy', defaultSearchOption.sortBy)
        searchParams.append('sortOrder', defaultSearchOption.sortOrder)
      }
    }

    return searchParams
  }, [query, sortingOptions])

  const { data, isLoading: isLoadingInitialState } = useInitialProductsRequest({
    id,
    type,
    queryParams: updatedSearchParams.toString(),
    endpointPath: getInitialProducts
  })
  const [fetchProducts, { isFetching: isLoadingProducts }] = useLazyGetListProductsQuery()

  useEffect(() => {
    dispatch(setSelectedAvailableFilters(filterConfig))
    // eslint-disable-next-line -- Only on mount
  }, [])

  const shouldLoadNewProductList = id !== productsState.productsListId

  useEffect(() => {
    if (shouldLoadNewProductList && data?.initialProductsState) {
      dispatch(setSelectedProductListId(id))
      dispatch(setSelectedProducts(data.initialProductsState))
      dispatch(setSelectedProductFilter({ filter: data.initialFilterState }))
    }
  }, [data, dispatch, filterConfig, id, shouldLoadNewProductList])

  const isNextPageAvailable = useMemo(() => {
    const shownElements = productsState.content?.length || 0
    return shownElements < productsState.totalElements
  }, [productsState])

  const loadMore = useCallback(() => {
    // todo: we never reset productsState.size, even when we move to a new page with new product list
    const newSize = productsState.size + PAGE_SIZE
    const params = {
      ...productsState.filter,
      config: {
        size: newSize,
        queryParams: {
          'sort.by': updatedSearchParams.get('sortBy'),
          'sort.order': updatedSearchParams.get('sortOrder')
        }
      }
    } as GetProductEndpointPayload

    void fetchProducts(params)
    // updates the state
    dispatch(setSelectedSize(newSize))
    dispatch(setSelectedProductFilter(params))
  }, [dispatch, fetchProducts, productsState.filter, productsState.size, updatedSearchParams])

  return {
    products: productsState.content || data?.initialProductsState.content || [],
    totalElements: productsState.totalElements || 0,
    isNextPageAvailable,
    loadMore,
    isLoading: isLoadingProducts || isLoadingInitialState
  }
}

export default useProductList
