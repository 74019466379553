import { type CartItem, type DiscountDTO, type ProductLightDTO } from 'ecosystem'

const calculateProductDiscountPrice = (product: ProductLightDTO, discountCode: DiscountDTO) => {
  const discountRate = discountCode.value / 100

  const isDiscountCandidate =
    (product.brand?.id && discountCode.brandIds?.includes(product.brand.id)) ||
    discountCode.categoryIds?.some((c) => product.categoryIds?.includes(c)) ||
    discountCode.tagIds?.some((t) => product.tags?.map((ptag) => ptag.id).includes(t))

  const isOriginalPriceLower = product.price > product.originalPrice

  if (isDiscountCandidate) {
    if (!discountCode.campaign && !isOriginalPriceLower) {
      const itemDiscount = product.originalPrice * discountRate

      return product.originalPrice - itemDiscount
    }
    const itemDiscount = product.price * discountRate

    return product.price - itemDiscount
  }

  return product.price
}

export const applyDiscountToCart = (
  cartItems: CartItem[],
  discountCode: DiscountDTO
): CartItem[] => {
  return cartItems.map((cartItem) => {
    const newItem = structuredClone(cartItem)

    if (newItem.type === 'SINGLE') {
      newItem.meta.singleType.product.price = calculateProductDiscountPrice(
        newItem.meta.singleType.product,
        discountCode
      )
    } else if (newItem.type === 'GROUP') {
      newItem.meta.groupType.items.forEach((groupItem) => {
        groupItem.product.price = calculateProductDiscountPrice(groupItem.product, discountCode)
      })
    }

    return newItem
  })
}
