import type { FC } from 'react'
import type { StackProps } from '@chakra-ui/react'
import { Price } from 'ui'

interface IProductDetailPriceProps extends StackProps {
  price: number
  originalPrice: number
}

const ProductDetailPrice: FC<IProductDetailPriceProps> = ({ price, originalPrice, ...props }) => {
  return (
    <Price
      className="product-detail-price"
      flex={0}
      originalPrice={originalPrice}
      originalPriceProps={{
        textAlign: { base: 'center', md: 'left' },
        fontSize: {
          base: 'md',
          md: '2xl'
        },
        fontWeight: 'bold'
      }}
      price={price}
      priceProps={{
        textAlign: { base: 'center', md: 'left' },
        fontSize: {
          base: 'md',
          md: '2xl'
        },
        fontWeight: 'bold'
      }}
      {...props}
    />
  )
}

export default ProductDetailPrice
